import React, { createContext, useEffect, useState } from "react";
import Navbar from "./layouts/Navbar";
import CardsContainer from "./layouts/CardsContainer";
import Sidebar from "./layouts/Sidebar";
import classes from "./index.module.css";

export const IframeContext = createContext();

export default function ConsoleAIDashboard() {
  const [selectedIframe, setSelectedIframe] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const selectIframe = (url, label) => {
    setSelectedIframe({ url, label });
  };

  const unSelectIframe = () => {
    setSelectedIframe(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsSidebarOpen(true);
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = selectedIframe ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [selectedIframe]);

  return (
    <div className={classes.mainContainer}>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {isSidebarOpen && <div className={classes.overlay} onClick={toggleSidebar}></div>}

      <div className={classes.rightSection}>
        <IframeContext.Provider
          value={{ selectedIframe, selectIframe, unSelectIframe }}
        >
          <Navbar toggleSidebar={toggleSidebar} />
          <CardsContainer />
        </IframeContext.Provider>
      </div>

      {selectedIframe && (
        <iframe
          className={classes.iframeContainer}
          src={selectedIframe.url}
          title={selectedIframe.label}
        />
      )}
      {/* {selectedIframe && (
        <div className={classes.iframeContainer}>
          <ChatContainer />
        </div>
      )} */}
    </div>
  );
}
