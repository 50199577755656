import CardsRow from "../components/CardsRow";
import { dashboardCardsData } from "../utils/data";
import classes from "./CardsContainer.module.css";

export default function CardsContainer() {
  return (
    <div className={classes.mainContainer}>
      <CardsRow cards={dashboardCardsData} />
    </div>
  );
}
