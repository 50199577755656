import Card from "./Card";
import classes from "./CardsRow.module.css";

export default function CardsRow({ cards }) {
  return (
    <div className={classes.cardsRow}>
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </div>
  );
}
