import {
  AINegotiatorIcon,
  AskMeAnytyhingIcon,
  AutoAdjuctorIcon,
  AutoSolveIcon,
  CodeMasterIcon,
  ContentCreatorIcon,
  ContractAssistIcon,
  CoPilotReportAccumulatorIcon,
  DocReaderIcon,
  DocumentiaIcon,
  FeedbackSummaryIcon,
  HospitalAIMotorAIIcon,
  LeadGeneratorIcon,
  ProductAssessorIcon,
  QuickSolveIcon,
  RiskAnalyzerIcon,
  SalesBuddyIcon,
  SuspiciousClaimsIcon,
  TicketAnalysisIcon,
} from "../assets";

export const dashboardCardsData = [
  {
    id: "1",
    category: "Claims",
    label: "AI Negotiator",
    iframeUrl: "https://hdfc-ergo-claim-analysis-367527410190.us-central1.run.app/",
    imageComponent: <AINegotiatorIcon />,
    disableCard: false
  },
  {
    id: "2",
    category: "Claims",
    label: "Hospital.AI",
    iframeUrl: "https://hdfc-ergo-frontend-367527410190.us-central1.run.app/analyse-medical-report",
    imageComponent: <HospitalAIMotorAIIcon />,
    disableCard: false
  },
  {
    id: "3",
    category: "Sales",
    label: "Explore",
    iframeUrl: "https://hdfcergo.salesbuddy.life/",
    imageComponent: <SalesBuddyIcon />,
    disableCard: false
  },
  {
    id: "4",
    category: "Sales",
    label: "Documentia",
    iframeUrl: "https://hdfcergo.documentia.ai/home/signin",
    imageComponent: <DocumentiaIcon />,
    disableCard: false
  },
  {
    id: "5",
    category: "Risk",
    label: "RxSure",
    iframeUrl: "https://hdfc-ergo-frontend.vercel.app/",
    imageComponent: <SuspiciousClaimsIcon />,
    disableCard: false
  },
  {
    id: "6",
    category: "Sales",
    label: "Product Assesor",
    iframeUrl: "https://hdfc-ergo-competitor-quote-analysis-367527410190.us-central1.run.app/",
    imageComponent: <ProductAssessorIcon />,
    disableCard: false
  },
  {
    id: "7",
    category: "Sales",
    label: "Ask Me Anything",
    iframeUrl: "https://genai.test-uat.com/dashboard",
    imageComponent: <AskMeAnytyhingIcon />,
    disableCard: false
  },
  {
    id: "8",
    category: "Legal & Productivity",
    label: "Legal Mind.AI",
    iframeUrl: "https://hdfc-legal-frontend.vercel.app/",
    imageComponent: <DocReaderIcon />,
    disableCard: false
  },
  {
    id: "9",
    category: "Risk",
    label: "Audio Adjuctor",
    iframeUrl: "http://hdfcergo.zscore.co.in/",
    imageComponent: <AutoAdjuctorIcon />,
    disableCard: true
  },
  {
    id: "10",
    category: "Risk",
    label: "Risk Analyzer",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <RiskAnalyzerIcon />,
    disableCard: true
  },
  {
    id: "11",
    category: "Legal & Productivity",
    label: "Contact Assist",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <ContractAssistIcon />,
    disableCard: true
  },
  {
    id: "12",
    category: "Legal & Productivity",
    label: "Code Master",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <CodeMasterIcon />,
    disableCard: true
  },
  {
    id: "13",
    category: "Legal & Productivity",
    label: "Quick Solve",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <QuickSolveIcon />,
    disableCard: true
  },
  {
    id: "14",
    category: "Claims",
    label: "Auto Solve",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <AutoSolveIcon />,
    disableCard: true
  },
  {
    id: "15",
    category: "Claims",
    label: "Co-Pilot Report Accumulator",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <CoPilotReportAccumulatorIcon />,
    disableCard: true
  },
  {
    id: "16",
    category: "Engagement/Marketing",
    label: "Lead Generator",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <LeadGeneratorIcon />,
    disableCard: true
  },
  {
    id: "17",
    category: "Engagement/Marketing",
    label: "Content Creator",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <ContentCreatorIcon />,
    disableCard: true
  },
  {
    id: "18",
    category: "Engagement/Marketing",
    label: "Ticket Analysis",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <TicketAnalysisIcon />,
    disableCard: true
  },
  {
    id: "19",
    category: "Engagement/Marketing",
    label: "Feedback Summary",
    iframeUrl: "https://motor.hdfcergo.com/",
    imageComponent: <FeedbackSummaryIcon />,
    disableCard: true
  },
];
