import React from "react";
import {
  AngularRightBracketIcon,
  LogoutIcon,
  NotificationIcon,
  OneUpIcon,
  ProfileIcon,
  SidebarProfileIcon,
} from "../assets/icons";
import classes from "./Sidebar.module.css";

export default function Sidebar({ isOpen, toggleSidebar }) {
  return (
    <div className={`${classes.sidebar} ${!isOpen ? classes.closed : ""}`}>
      <h1 className={classes.profile}>
        <ProfileIcon /> Welcome
      </h1>
      <ul className={classes.list}>
        <li className={classes.listitems}>
          <AngularRightBracketIcon />
          <NotificationIcon />
          Notifications
        </li>
        <li className={classes.listitems}>
          <AngularRightBracketIcon />
          <SidebarProfileIcon />
          Account
        </li>
        <li className={classes.listitems}>
          <AngularRightBracketIcon />
          <LogoutIcon />
          Logout
        </li>
        <div className={classes.listitemslogo}>
          <hr />
          <OneUpIcon />
          <hr />
        </div>
        <li className={classes.listitems}>
          <AngularRightBracketIcon />
          Past Actions
        </li>
      </ul>
    </div>
  );
}
