import React, { useCallback, useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Navigation from "../Layouts/Navigation/Navigation";
import SideBar from "../Layouts/SideBar/SideBar";
import classes from "./index.module.css";
import PrivateRoute from "./PrivateRoute";
import Loader from "../CommonComponents/Loader/Loader";
import ScrollToTop from "../Layouts/ScrollToTop/ScrollToTop";
import Footer from "../Layouts/Footer/Footer";
import BottomNavigation from "../Layouts/BottomNavigation/BottomNavigation";
import ConsoleAIDashboard from "../ConsoleAI";

const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const CaseManagement = lazy(
  () => import("../Pages/CaseManagement/CaseManagement")
);
const VendorManagement = lazy(
  () => import("../Pages/VendorManagement/VendorManagement")
);
const Approval = lazy(() => import("../Pages/Approval/Approval"));
const LoginPage = lazy(() => import("../Pages/Login/LoginPage"));
const CreateCase = lazy(() => import("../Pages/CreateCase/CreateCase"));
const Invoices = lazy(() => import("../Pages/Invoice/Invoices"));
const UploadedInvoices = lazy(
  () => import("../Pages/Invoice/UploadedInvoices")
);
const FMRFormComponent = lazy(
  () => import("../CommonComponents/CustomizedForm/FMRFormComponent")
);
const MisReports = lazy(() => import("../Pages/MisReports/MisReports"));
const Assignblock = lazy(
  () => import("../CommonComponents/AssignBlocks/Assignblock")
);
const CaseSearch = lazy(() => import("../Pages/CaseSearch/CaseSearch"));
const FeedbackForm = lazy(() => import("../Pages/SMSFeedback/FeedbackForm"));
const VendorOnboardingMain = lazy(
  () => import("../Pages/VendorManagement/VendorOnboardingMain")
);
const CaseSearchTable = lazy(
  () => import("../Pages/CaseSearch/CaseSearchTable")
);
const CaseSearchView = lazy(() => import("../Pages/CaseSearch/CaseSearchView"));

const Index = () => {
  const { token: isAuthenticated } = useSelector((state) => state.loginSlice);
  const isLoadingState = useSelector(
    (state) => state.loginSlice.isLoadingState
  );
  const [showScroll, setShowScroll] = useState(false);
  const location = useLocation();

  const handleNavigation = useCallback(() => {
    if (window.scrollY === 0) {
      setShowScroll(false);
    } else {
      setShowScroll(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  // temp code for console AI
  if (location.pathname === "/fusion") {
    return (
      <Routes>
        <Route path={"/fusion"} element={<ConsoleAIDashboard />} />
      </Routes>
    );
  }

  return location?.pathname !== "/feedback" ? (
    <div className={classes["main-container"]}>
      {isLoadingState && <Loader />}
      {isAuthenticated && <Navigation />}
      {isAuthenticated && <BottomNavigation />}
      <div className={classes["sidebar-and-content-conatiner"]}>
        {isAuthenticated && <SideBar />}
        <div
          className={`${classes["main-contents"]} ${classes["main-content"]}`}
        >
          <div className={classes["router-conatiner"]}>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route element={<PrivateRoute />}>
                  <Route path={"/home"} element={<Dashboard />} />
                  <Route path={"/assignBlock"} element={<Assignblock />} />
                  <Route
                    path={"/re-assign/:vendorId/:reassignType"}
                    element={<Assignblock isReassign={true} />}
                  />
                  <Route
                    path={"/caseManagement"}
                    element={<CaseManagement />}
                  />
                  {/* <Route
                  path={"/vendorManagement"}
                  element={<VendorManagement />}
                /> */}
                  <Route
                    path={"/vendorOnboarding"}
                    element={<VendorOnboardingMain />}
                  />
                  <Route path={"/createCase"} element={<CreateCase />} />
                  <Route path={"/approval"} element={<Approval />} />
                  <Route path={"/reports"} element={<MisReports />} />
                  <Route
                    path={"/fmr-report-download"}
                    element={<FMRFormComponent />}
                  />
                  <Route path={"/caseSearch"} element={<CaseSearch />} />
                  <Route
                    path={"/caseSearchResult"}
                    element={<CaseSearchTable />}
                  />
                  <Route
                    path={"/caseSearchView"}
                    element={<CaseSearchView />}
                  />
                </Route>
                <Route path={"/login"} element={<LoginPage />} />
                <Route path={"/"} element={<Navigate to="/home" />} />
                {/* <Route path={"/invoice"} element={<Invoices />} /> */}
                <Route
                  path={"*"}
                  element={
                    <Box
                      sx={{
                        marginTop: "30px",
                        textAlign: "center",
                        color: "#00000E6",
                      }}
                    >
                      <h1>Coming Soon</h1>
                    </Box>
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
      {isAuthenticated && showScroll && <ScrollToTop />}
      <Footer />
    </div>
  ) : (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={"/feedback"} element={<FeedbackForm />} />
      </Routes>
    </Suspense>
  );
};

export default Index;
