import React, { useContext } from "react";
import { IframeContext } from "../index";
import { HdfcErgoLogo } from "../assets";
import { BackIcon, MenuIcon } from "../assets/icons";
import classes from "./Navbar.module.css";

export default function Navbar({ toggleSidebar }) {
  const { selectedIframe, unSelectIframe } = useContext(IframeContext);

  return (
    <div className={classes.navbar}>
      <div className={classes.leftIcons}>
        {selectedIframe && (
          <button className={classes.backButton} onClick={unSelectIframe}>
            <BackIcon />
          </button>
        )}
        <button className={classes.hamburgerButton} onClick={toggleSidebar}>
          <MenuIcon />
        </button>
      </div>
      <h1 className={classes.title}>
        {selectedIframe?.label || "Fusion"}
      </h1>
      <div className={classes.rightIcons}>
        <HdfcErgoLogo />
      </div>
    </div>
  );
}
