import { useContext } from "react";
import { IframeContext } from "../index";
import classes from "./Card.module.css";

export default function Card({ disableCard, label, iframeUrl, imageComponent }) {
  const { selectIframe } = useContext(IframeContext);

  const handleClick = () => {
    selectIframe(iframeUrl, label);
  };

  return (
    <>
      <div
        className={`${classes.card} ${disableCard ? classes.disabled : ""}`}
        onClick={handleClick}
      >
        <div className={classes.imageContainer}>{imageComponent}</div>
        <h3>{label}</h3>
      </div>
    </>
  );
}
